/* eslint no-param-reassign:0 */
import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Player from "../components/player"
import Timer from "../components/timer"
import { ReadSpellToggle } from "../components/toggle"
import dash from "../images/dash.svg"
import Tracker from "../components/tracker"

const CardWrapper = styled.div`
  padding-right: 2rem;
  padding-left: 2rem;
`

const StyledHeader = styled.b`
  font-size: 2rem;
  margin-right: 0.75rem;
`

const TitleWrapper = styled.div`
  padding-top: 1rem;
  padding-bottom: 2rem;
  padding-left: 6rem;
  @media (max-width: 769px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
`

const StyledCard = styled.div`
  border-radius: 2rem;
  box-shadow: 0 0.25rem 0.5rem 0.1rem #ddd;
  position: relative;
  color: #4a4a4a;
  max-width: 100%;
  background-color: white;
`

const StyledCell = styled.div`
  border: 0.1rem solid #dbdbdb;
  font-size: 1.5rem;
`

const ToggleWrapper = styled.div`
  padding-bottom: 2em;
`

const GridWrapper = styled.div`
  padding: 2rem;
  background-color: transparent;
`

const Controls = styled.div`
  @media (min-width: 769px) {
    background-color: #5ed0fe;
    align-items: stretch;
    display: flex;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    height: 4rem;
    padding: 0 1rem;
  }
  @media (max-width: 768px) {
    background-color: #5ed0fe;
    display: flex;
    height: 5rem;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }
`

const ControlItemWrapper = styled.div`
  @media (min-width: 769px) {
    align-items: center;
    display: flex;
    flex-basis: 0;
    flex-shrink: 0;
    flex-grow: 1;
    justify-content: center;
    padding: 0.75rem 0.5rem;
  }
  @media (max-width: 768px) {
    flex-basis: 0;
    flex-shrink: 0;
    flex-grow: 1;
    padding: 0.75rem 0.5rem;
  }
`

const ControlItem = styled.button`
  width: 100%;
  height: 100%;
  border-radius: 0.75rem;
  background-color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  text-align: center;
  color: #4a4a4a;
  font-size: 1em;
  border: none;
`

const Dash = styled.img`
  width: 5rem;
  display: block;
`

const AlertWrapper = styled.div`
  margin-bottom: 2rem;
`

const DropdownWrapper = styled.div`
  @media (min-width: 769px) {
    float: right;
  }
  display: block;
  text-align: center;
  padding-top: 1rem;
`

class SpeedgridTemplate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      spell: false,
      goal: this.props.data.strapiSpeedgrid.ReadingGoal,
      alert: false,
      rand: new Date(),
      spellingWords: this.spellingWords,
      readingWords: this.readingWords,
      words: [],
      drama: false,
      wpm: 0,
      wpmGoal: 0,
    }

    this.methodHandler = this.methodHandler.bind(this)
    this.timeHandler = this.timeHandler.bind(this)
    this.spellHandler = this.spellHandler.bind(this)
    this.timeRef = React.createRef()
    this.cells = []
  }

  get spellingWords() {
    const length = this.props.data.strapiSpeedgrid.Words.words.length
    let size = -1
    size = this.props.data.strapiSpeedgrid.SpellingSize
    if (size === undefined || size <= 0) {
      size = 24
    }
    return this.trim(
      this.shuffle(
        this.repeat(
          this.props.data.strapiSpeedgrid.Words.words,
          Math.ceil(size / length)
        )
      ),
      size
    )
  }

  get readingWords() {
    const length = this.props.data.strapiSpeedgrid.Words.words.length
    let size = -1
    size = this.props.data.strapiSpeedgrid.ReadingSize
    if (size === undefined || size <= 0) {
      size = 24
    }
    return this.trim(
      this.shuffle(
        this.repeat(
          this.props.data.strapiSpeedgrid.Words.words,
          Math.ceil(size / length)
        )
      ),
      size
    )
  }

  componentWillMount() {
    if (this.state.spell === true) {
      this.setState((prevState) => ({
        words: prevState.spellingWords,
        wpmGoal: this.updateWpmGoal(prevState.spellingWords.length, prevState.goal),
      }))
    }
    if (this.state.spell === false) {
      this.setState((prevState) => ({
        words: prevState.readingWords,
        wpmGoal: this.updateWpmGoal(prevState.readingWords.length, prevState.goal),
      }))
    }
  }

  updateWpmGoal(size, goal) {
    return Math.ceil((size / goal) * 60)
  }

  trim(array, size) {
    if (array.length !== size) {
      return array.slice(0, size - array.length)
    }
    return array
  }

  shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[a[i], a[j]] = [a[j], a[i]]
    }
    return a
  }

  repeat(array, times) {
    let newArray = []
    for (let i = 0; i < times; i++) {
      newArray = [...newArray, ...array]
    }
    return newArray
  }

  methodHandler() {
    if (this.props.data.strapiSpeedgrid.SpellingGoal > 0) {
      if (this.state.spell === false) {
        this.setState((prevState) => ({
          goal: this.props.data.strapiSpeedgrid.SpellingGoal,
          spell: true,
          words: prevState.spellingWords,
        }))
      } else if (this.state.spell === true) {
        this.setState((prevState) => ({
          goal: this.props.data.strapiSpeedgrid.ReadingGoal,
          spell: false,
          words: prevState.readingWords,
        }))
      }
    }
  }

  timeHandler() {
    let misspell = false

    if (this.state.spell === true) {
      for (let cell of this.cells) {
        if (cell != null && cell.name !== cell.value) {
          misspell = true
        }
      }
    }

    if (this.timeRef.current.state.lastAction === "start") {
      this.setState((prevState) => ({
        wpm: Math.ceil(
          (prevState.words.length / ((this.timeRef.current.state.time / 1000) - 1 )) * 60 
        ),
      }))
    }

    if (
      misspell === false &&
      this.timeRef.current.state.lastAction === "start" &&
      Math.floor((this.timeRef.current.state.time / 1000) % 60) <
        parseInt(this.state.goal, 10)
    ) {
      this.setState({ alert: true })
    }

    if (
      this.state.spell === true &&
      this.timeRef.current.state.lastAction === "stop"
    ) {
      const freshWords = this.spellingWords
      this.setState(
        {
          alert: false,
          rand: new Date(),
          spellingWords: freshWords,
          words: [],
          drama: true,
          wpm: 0,
        },
        () => {
          this.cells = []
        }
      )
      setTimeout(() => {
        this.setState((prevState) => ({
          words: prevState.spellingWords,
          drama: false,
        }))
      }, Math.floor(Math.random() * (2000 - 1000 + 1) + 1000))
    }
    if (
      this.state.spell === false &&
      this.timeRef.current.state.lastAction === "stop"
    ) {
      const freshWords = this.readingWords
      this.setState({
        alert: false,
        rand: new Date(),
        readingWords: freshWords,
        words: [],
        drama: true,
        wpm: 0,
      })
      setTimeout(() => {
        this.setState((prevState) => ({
          words: prevState.readingWords,
          drama: false,
        }))
      }, Math.floor(Math.random() * (2000 - 1000 + 1) + 1000))
    }
  }

  spellHandler(event) {
    let next = Number(event.target.attributes.cellsref.value) + 1
    if (event.target.value === event.target.name && next < this.cells.length) {
      this.cells[next].focus()
      setTimeout(() => this.cells[next].click(), 500)
      this.setState((prevState) => ({
        wpm: Math.ceil(
          (prevState.words.length / ((this.timeRef.current.state.time / 1000) - 1 )) * 60
        ),
      }))
    }
  }

  render() {
    return (
      <Layout>
        <SEO title="SpeedGrid" />

        <TitleWrapper className="columns">
          <div className="column">
            <h2 className="subtitle is-4">
              <StyledHeader>Speed Grid: </StyledHeader>
              {this.props.data.strapiSpeedgrid.Name}&nbsp;
              <Tracker id={this.props.data.strapiSpeedgrid.id} />
              <Dash src={dash} alt="some dashes" />
              <div className="is-hidden-desktop">Goal: {this.state.goal}s</div>
            </h2>
          </div>
        </TitleWrapper>

        <ToggleWrapper
          className={
            this.props.data.strapiSpeedgrid.ReadingOnly
              ? "is-hidden"
              : "columns is-centered"
          }
        >
          <div className="column">
            <div
              role="button"
              tabIndex="0"
              onClick={this.methodHandler}
              onKeyPress={this.methodHandler}
            >
              <ReadSpellToggle toggle={this.state.spell ? undefined : true} />
            </div>
            {this.state.spell === true && (
              <DropdownWrapper className="dropdown is-hoverable is-right">
                <div className="dropdown-trigger">
                  <button className="button" aria-haspopup="true" type="button">
                    <span>See All Words</span>
                  </button>
                </div>
                <div className="dropdown-menu">
                  <div className="dropdown-content">
                    {this.props.data.strapiSpeedgrid.Words.words
                      .sort()
                      .map((word) => (
                        <div className="dropdown-item" key={word}>
                          {word}
                        </div>
                      ))}
                  </div>
                </div>
              </DropdownWrapper>
            )}
          </div>
        </ToggleWrapper>

        <AlertWrapper className={this.state.alert ? undefined : "is-hidden"}>
          <div className="notification is-success has-text-centered">
            <button
              className="delete"
              type="button"
              onClick={() =>
                this.setState((prevState) => ({ alert: !prevState.alert }))
              }
            >
              Close banner
            </button>
            Congratulations, you beat the goal! 🎉
          </div>
        </AlertWrapper>

        <div className="columns">
          <CardWrapper className="column is-full">
            <StyledCard>
              <GridWrapper>
                <div className="columns is-multiline">
                  {this.state.drama ? (
                    <div className=" column has-text-centered">
                      <h2 className="title">Scrambling Words</h2>
                      <progress class="progress is-primary" max="100">
                        Scrambling Words
                      </progress>
                    </div>
                  ) : undefined}
                  {this.state.words.map((word, key) => (
                    <StyledCell
                      className="column is-3"
                      key={`${this.state.rand}-${key}-${word}`}
                    >
                      <Player source={word}>
                        {this.state.spell ? (
                          <input
                            cellsref={key}
                            type="text"
                            className="input"
                            onChange={this.spellHandler}
                            name={word}
                            ref={(ref) => {
                              this.cells.push(ref)
                            }}
                          />
                        ) : (
                          word
                        )}
                      </Player>
                    </StyledCell>
                  ))}
                </div>
              </GridWrapper>
              <Controls>
                <ControlItemWrapper>
                  <ControlItem>
                    Goal: {this.state.goal}
                    {" seconds  "}
                    {this.state.wpmGoal} WPM
                  </ControlItem>
                </ControlItemWrapper>
                <Timer
                  ref={this.timeRef}
                  goal={this.state.goal}
                  spell={this.state.spell}
                  onClick={this.timeHandler}
                />
                <ControlItemWrapper>
                  <ControlItem>WPM: {this.state.wpm}</ControlItem>
                </ControlItemWrapper>
              </Controls>
            </StyledCard>
          </CardWrapper>
        </div>
      </Layout>
    )
  }
}
SpeedgridTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default SpeedgridTemplate

export const query = graphql`
  query SpeedgridTemplate($id: String!) {
    strapiSpeedgrid(id: { eq: $id }) {
      id
      Name
      ReadingOnly
      ReadingSize
      SpellingSize
      Words {
        words
      }
      ReadingGoal
      SpellingGoal
    }
  }
`
