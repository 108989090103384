import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const ControlItemWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-basis: 0;
  flex-shrink: 0;
  flex-grow: 1;
  justify-content: center;
  padding: 0.75rem 0.5rem;
`

const ControlItem = styled.button`
  width: 100%;
  height: 100%;
  border-radius: 0.75rem;
  background-color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  text-align: center;
  color: #4a4a4a;
  font-size: 1em;
  border: none;
`

class Timer extends React.Component {
  static convertToString(ms, delim = ":") {
    const showWith0 = (value) => (value < 10 ? `0${value}` : value)
    const hours = showWith0(Math.floor((ms / (1000 * 60 * 60)) % 60))
    const minutes = showWith0(Math.floor((ms / (1000 * 60)) % 60))
    const seconds = showWith0(Math.floor((ms / 1000) % 60))
    return `${
      parseInt(hours, 10) ? `${hours}${delim}` : ""
    }${minutes}${delim}${seconds}`
  }

  constructor(props) {
    super(props)
    this.state = {
      time: 0,
      start: 0,
      lastAction: "off",
      isOn: false,
    }
    this.startTimer = this.startTimer.bind(this)
    this.stopTimer = this.stopTimer.bind(this)
    this.resetTimer = this.resetTimer.bind(this)
  }

  startTimer() {
    this.setState((prevState) => ({
      time: prevState.time,
      start: Date.now() - prevState.time,
      isOn: true,
      lastAction: "start",
    }))
    this.timer = setInterval(
      () =>
        this.setState((prevState) => ({
          time: Date.now() - prevState.start,
        })),
      1
    )
  }

  stopTimer() {
    this.setState({ isOn: false, lastAction: "stop" })
    clearInterval(this.timer)
  }

  resetTimer() {
    this.setState({ time: 0, lastAction: "reset" })
  }

  render() {
    const start =
      this.state.time === 0 ? (
        <ControlItem
          onClick={this.startTimer}
          className="has-background-grey-dark has-text-success is-uppercase is-size-4"
        >
          Start Timer
        </ControlItem>
      ) : null
    const stop = this.state.isOn ? (
      <ControlItem
        onClick={this.stopTimer}
        className="has-background-grey-dark has-text-danger is-uppercase is-size-4"
      >
        Stop
      </ControlItem>
    ) : null
    const reset =
      this.state.time !== 0 && !this.state.isOn ? (
        <ControlItem
          onClick={this.resetTimer}
          className="has-background-grey-dark has-text-info is-uppercase is-size-4"
        >
          Reset
        </ControlItem>
      ) : null
    // const resume =
    //   this.state.time !== 0 && !this.state.isOn ? (
    //     <ControlItem onClick={this.startTimer}>
    //       Resume
    //     </ControlItem>
    //   ) : null
    return (
      <>
        <ControlItemWrapper onClick={this.props.onClick}>
          {/* {start} {resume} {stop} {reset} */}
          {start} {stop} {reset}
        </ControlItemWrapper>
        <ControlItemWrapper>
          <ControlItem>
            Your Time {Timer.convertToString(this.state.time)}
          </ControlItem>
        </ControlItemWrapper>
      </>
    )
  }
}

Timer.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default Timer
